<template>
  <tbb-card title="BOOKING SERVICE DETAILS">
    <b-card class="mt-2">
      <b-list-group flush>
        <b-list-group-item
          v-for="(service, index) in selectedServicesStore"
          :key="index"
          class="d-flex justify-content-between"
        >
          <div>
            <span class="font-weight-bolder">{{ service.name }}</span> <span class="text-muted text-nowrap">({{ service.duration }} min)</span>
          </div>
          <span>${{ service.price | price }}</span>
          <feather-icon
            v-b-tooltip.hover.top="'Remove service'"
            icon="XCircleIcon"
            class="position-absolute cursor-pointer remove-service"
            alt="Remove service"
            size="15"
            @click="removeService(service)"
          />
        </b-list-group-item>
      </b-list-group>
      <div class="padding-list-group-item d-flex align-items-center justify-content-end">
        <span
          class="cursor-pointer add-service"
          @click="$router.push({ name: stylistPagePath })"
        > Add service
          <feather-icon
            icon="PlusCircleIcon"
            class="cursor-pointer text-success"
            size="15"
          />
        </span>
      </div>
      <div class="total padding-list-group-item d-flex justify-content-between">
        <span>Total</span>
        <span class="text-right font-weight-bolder">
          ${{ selectedServicesStore | sumPrice }}
        </span>
      </div>
    </b-card>
  </tbb-card>
</template>

<script>
import {
  BCard, BListGroup, BListGroupItem, VBTooltip,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import { STYLIST_PAGE } from '@/router/routes/routes-names'
import CryptoJS from 'crypto-js'
import TbbCard from '../../sites/TbbCard.vue'

export default {
  filters: {
    sumPrice(selectedServices) {
      let price = 0
      if (selectedServices) {
        selectedServices.forEach(service => {
          price += +service.price
        })
      }
      return price.toFixed(2)
    },
    price(price) {
      return Number(price).toFixed(2)
    },
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BListGroup,
    BListGroupItem,
    TbbCard,
  },
  data() {
    return {
      stylistPagePath: STYLIST_PAGE,
    }
  },
  computed: {
    ...mapState('BookingServiceStoreModule', {
      selectedServicesStore: state => state.selectedServices,
    }),
  },
  watch: {
    selectedServicesStore: {
      deep: true,
      handler(newSelectedServicesStore) {
        if (!newSelectedServicesStore.length) {
          this.$router.push({ name: STYLIST_PAGE })
        }
      },
    },
  },
  methods: {
    ...mapActions('BookingServiceStoreModule', [
      'setSelectedServices',
    ]),
    removeService(serviceToDelete) {
      this.setSelectedServices(this.selectedServicesStore.filter(service => service !== serviceToDelete))
      const selectedServices = JSON.parse(JSON.stringify(this.selectedServicesStore))
      if (selectedServices.length) {
        const servicesCrypted = CryptoJS.AES.encrypt(
          JSON.stringify(selectedServices),
          process.env.VUE_APP_SECRET_KEY,
        ).toString()
        this.$cookies.set('booking_services', servicesCrypted, '15min')
      } else {
        this.$cookies.remove('booking_services')
      }
    },
  },
}
</script>
<style lang="scss" scoped>
  .list-group-item{
    padding-left: 0;
    &:hover{
      background-color: #fff;
    }
  }
  .padding-list-group-item {
    padding: 0.75rem 0;
  }
  .total {
    border-top: 1px solid #d1b08f;
    padding-right: 1.25rem;
  }
  .remove-service {
    color: red;
    right: 0;
    top: 1rem;
  }
  .add-service{
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    font-size: 0.85em;
    letter-spacing: 0.05em;
    color: #aaa;
    svg{
      margin-left: 0.25rem;
    }
  }
</style>
