<template>
  <div class="mb-4">
    <div class="d-flex justify-content-center">
      <div
        v-on-clickaway="closePicker"
        class="d-flex justify-content-center align-items-center"
      >
        <feather-icon
          v-if="availableDates && startWeekDate > currentDay"
          icon="ChevronLeftIcon"
          size="32"
          class="cursor-pointer arrow-color"
          @click="moveLeft"
        />
        <div
          class="d-flex justify-content-center flex-column align-items-center cursor-pointer date-title"
          @click="openPicker"
        >
          <span class="month-text font-large-1">
            {{ startWeekDate | monthString }} <span v-if="!sameMonths"> - {{ endWeekDate | monthString }}</span>
          </span>
          <span
            class="date-period"
          >{{ startWeekDate | dayNumberAndMonthNumber }} - {{ endWeekDate | dayNumberAndMonthNumber }}
          </span>
        </div>
        <datepicker
          ref="bookingServiceDatepicker"
          v-model="selectedDateFromPicker"
          class="datepicker-booking-service"
          :hidden="!showCalendar"
          start-weekday="1"
          :disabled-dates="{
            to: new Date(currentDay),
          }"
          :inline="true"
        />
        <feather-icon
          v-if="availableDates"
          icon="ChevronRightIcon"
          size="32"
          class="cursor-pointer arrow-color"
          @click="moveRight"
        />
      </div>
    </div>
    <div class="available-dates mt-3 mt-lg-5">
      <ul class="available-dates-list">
        <li
          v-for="(day) in availableDates"
          :key="day.date"
          :class="{
            'current-date': day.date === getCurrentDate(),
            'selected-date': day.date === selectedDate,
            'future-date': day.date > getCurrentDate(),
            'past-date': day.date < getCurrentDate(),
            'mostly-available cursor-pointer': day.availableHours.length >= 10,
            'few-available cursor-pointer': day.availableHours.length >= 1 && day.availableHours.length < 10,
            'non-available': day.availableHours.length === 0,
          }"
          @click="day.availableHours.length > 0 ? selectDate(day.date) : null"
        >
          <div
            class="available-date-item"
          >
            <div class="available-date-name-short">
              {{ day.date | dayName }}
            </div>
            <div class="available-date-day">
              {{ day.date | dayNumber }}
            </div>
            <div class="available-date-count">
              {{ day.availableHours.length }}
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div
      v-if="availableHoursForSelectedDate"
      class="hour-items"
    >
      <h3 class="text-center m-3">
        {{ selectedDate | dateUsLocale }}
      </h3>
      <div class="d-flex flex-wrap justify-content-center justify-content-md-start">
        <div
          v-if="availableHoursForSelectedDate.morning.length"
          class="mb-2 text-center w-100"
        >
          Morning
        </div>
        <div
          v-for="(hour, index) in availableHoursForSelectedDate.morning"
          :key="index"
          class="hour-item cursor-pointer text-center justify-content-center"
          @click="selectDateTime(hour)"
        >
          <span>
            {{ hour | hourUsLocale }}
          </span>
        </div>
        <div
          v-if="availableHoursForSelectedDate.afternoon.length"
          class="my-2 text-center w-100"
        >
          Afternoon
        </div>
        <div
          v-for="(hour, index) in availableHoursForSelectedDate.afternoon"
          :key="'A' + index"
          class="hour-item cursor-pointer text-center justify-content-center"
          @click="selectDateTime(hour)"
        >
          <span>
            {{ hour | hourUsLocale }}
          </span>
        </div>
        <div
          v-if="availableHoursForSelectedDate.afternoon.evening"
          class="my-2 text-center w-100"
        >
          Evening
        </div>
        <div
          v-for="(hour, index) in availableHoursForSelectedDate.evening"
          :key="'E' + index"
          class="hour-item cursor-pointer text-center justify-content-center"
          @click="selectDateTime(hour)"
        >
          <span>
            {{ hour | hourUsLocale }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import moment from 'moment'
import Datepicker from 'vuejs-datepicker'
import { mixin as clickaway } from 'vue-clickaway'
import { BOOKING_SECOND_STEP_PAGE, STYLIST_PAGE } from '@/router/routes/routes-names'
import CryptoJS from 'crypto-js'
import { AlertTriangleIcon } from 'vue-feather-icons'
import { TYPE } from 'vue-toastification'

export default {
  name: 'BookingServiceAvailableDate',
  components: {
    Datepicker,
  },
  filters: {
    dayName(date) {
      return moment(date).format('ddd')
    },
    dayNumber(date) {
      return moment(date).format('DD')
    },
    dayNumberAndMonthNumber(date) {
      return moment(date).format('DD.MM')
    },
    monthString(date) {
      return moment(date).format('MMMM')
    },
    dateUsLocale(date) {
      return moment(date).format('MMMM D, yyyy')
    },
    hourUsLocale(hour) {
      return moment(`${hour}`, 'H:mm').format('h:mm a')
    },
  },
  mixins: [clickaway],
  data() {
    return {
      selectedDateFromPicker: null,
      startWeekDate: moment().day(0).format('YYYY-MM-DD'),
      endWeekDate: moment().day(6).format('YYYY-MM-DD'),
      availableDates: null,
      availableHoursForSelectedDate: null,
      selectedDate: null,
      showCalendar: false,
      sameMonths: true,
      duration: 0,
      currentDay: moment().format('YYYY-MM-DD'),
    }
  },
  computed: {
    ...mapState('BookingServiceStoreModule', {
      availableDatesStore: state => state.availableDates,
      selectedServicesStore: state => state.selectedServices,
    }),
    ...mapState('StylistPageStoreModule', {
      openHoursStore: state => state.openHours,
    }),
  },
  watch: {
    selectedDateFromPicker: {
      deep: true,
      handler(newDate) {
        if (newDate) {
          this.showCalendar = false
          this.startWeekDate = moment(newDate, 'YYYY-MM-DD').day(1).format('YYYY-MM-DD')
          this.endWeekDate = moment(newDate, 'YYYY-MM-DD').day(7).format('YYYY-MM-DD')
          this.checkStartEndMonth()
          this.fetchAvailableDatesWithParams()
          this.availableHoursForSelectedDate = null
        }
      },
    },
    availableDatesStore: {
      deep: true,
      handler(newAvailableDates) {
        if (newAvailableDates.response) {
          this.availableDates = newAvailableDates.response
          if (!this.selectedDate && !!this.openHoursStore.response) {
            this.getFirstAvailableDay()
          }
        }
      },
    },
    selectedDate: {
      deep: true,
      handler(selectedDate) {
        if (selectedDate) {
          this.availableHoursForSelectedDate = this.getAvailableHoursForSelectedDate()
        }
      },
    },
    selectedServicesStore: {
      deep: true,
      handler(selectedServicesStore) {
        if (selectedServicesStore) {
          this.duration = 0
          selectedServicesStore.forEach(service => {
            this.duration += service.duration
          })

          this.fetchAvailableDatesWithParams()
        }
      },
    },
  },
  created() {
    if (this.$cookies.get('booking_services')) {
      if (!this.openHoursStore.response) {
        this.fetchPublicOpenHours()
      }
      this.fetchPublicStylist(this.$store.getters['app/getSubdomain'])
      this.setSelectedServices(JSON.parse(CryptoJS.AES.decrypt(this.$cookies.get('booking_services'), process.env.VUE_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8)))
      this.selectedServicesStore.forEach(service => {
        this.duration += service.duration
      })
    } else {
      this.$router.push({ name: STYLIST_PAGE })
    }
  },
  methods: {
    ...mapActions('BookingServiceStoreModule', [
      'fetchAvailableDates',
      'setSelectedDateTime',
      'setSelectedServices',
    ]),
    ...mapActions('StylistPageStoreModule', [
      'fetchPublicStylist',
      'fetchPublicOpenHours',
    ]),
    fetchAvailableDatesWithParams() {
      this.fetchAvailableDates({
        duration: this.duration,
        period: {
          startDate: this.startWeekDate,
          endDate: this.endWeekDate,
        },
        apiToken: this.$auth.apiToken,
      })
    },
    getAvailableHoursForSelectedDate() {
      const availableDate = this.availableDates.filter(obj => obj.date === this.selectedDate)
      return {
        morning: availableDate[0].availableHours.filter(obj => moment(obj, 'h:mma').isBefore(moment('12:00', 'h:mma'))),
        afternoon: availableDate[0].availableHours.filter(obj => moment(obj, 'h:mma').isAfter(moment('11:59', 'h:mma')) && moment(obj, 'h:mma').isBefore(moment('18:00', 'h:mma'))),
        evening: availableDate[0].availableHours.filter(obj => moment(obj, 'h:mma').isAfter(moment('17:59', 'h:mma'))),
      }
    },
    selectDate(selectedDate) {
      this.selectedDate = selectedDate
    },
    selectDateTime(hour) {
      const selectedDateTime = `${this.selectedDate} ${hour}`
      this.setSelectedDateTime(selectedDateTime)
      const period = {
        startsAt: moment(selectedDateTime).format('yyyy-MM-DD HH:mm:ss'),
        endsAt: moment(selectedDateTime).add(this.duration, 'minutes').format('yyyy-MM-DD HH:mm:ss'),
      }
      const reservedBookDateCrypted = CryptoJS.AES.encrypt(
        JSON.stringify(period),
        process.env.VUE_APP_SECRET_KEY,
      ).toString()
      const selectedDateTimeCrypted = CryptoJS.AES.encrypt(
        JSON.stringify(selectedDateTime),
        process.env.VUE_APP_SECRET_KEY,
      ).toString()
      this.$cookies.set('reserved_book_date', reservedBookDateCrypted, '5min')
      this.$cookies.set('selected_datetime', selectedDateTimeCrypted, '5min')
      this.$router.push({ name: BOOKING_SECOND_STEP_PAGE })
    },
    moveRight() {
      this.startWeekDate = moment(this.startWeekDate).add(7, 'days').format('YYYY-MM-DD')
      this.endWeekDate = moment(this.endWeekDate).add(7, 'days').format('YYYY-MM-DD')
      this.checkStartEndMonth()
      this.fetchAvailableDatesWithParams()
    },
    moveLeft() {
      this.startWeekDate = moment(this.startWeekDate).add(-7, 'days').format('YYYY-MM-DD')
      this.endWeekDate = moment(this.endWeekDate).add(-7, 'days').format('YYYY-MM-DD')
      this.checkStartEndMonth()
      this.fetchAvailableDatesWithParams()
    },
    getCurrentDate() {
      return moment().format('YYYY-MM-DD')
    },
    checkStartEndMonth() {
      this.sameMonths = moment(this.startWeekDate).format('MMMM') === moment(this.endWeekDate).format('MMMM')
    },
    openPicker() {
      this.showCalendar = true
      this.$refs.bookingServiceDatepicker.showCalendar()
    },
    closePicker() {
      if (this.showCalendar) {
        this.showCalendar = false
      }
    },
    getFirstAvailableDay() {
      let i = 0
      while (this.availableDates[i]) {
        if (this.availableDates[i].availableHours.length > 0) {
          this.selectedDate = this.availableDates[i].date
          break
        }
        i += 1
      }
      if (!this.selectedDate) {
        if (moment(this.currentDay).add(2, 'month').isBefore(this.endWeekDate)) {
          this.$toast('There is no availability, please contact stylist!', {
            icon: AlertTriangleIcon,
            type: TYPE.ERROR,
          })
        } else {
          this.startWeekDate = moment(this.startWeekDate).add(7, 'days').format('YYYY-MM-DD')
          this.endWeekDate = moment(this.endWeekDate).add(7, 'days').format('YYYY-MM-DD')
          this.checkStartEndMonth()
          this.fetchAvailableDatesWithParams()
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import "src/@core/scss/base/bootstrap-extended/_variables.scss";
@import "src/assets/scss/variables/_variables.scss";

$available-color: #E1F3FC;

.arrow-color{
  color: #aaa;
}

.date-title{
  min-width: 200px;
  padding: 0 1rem;
}
.current-date {
  box-shadow: inset 0 0 0 2px $primary;
  color: $gray-400;
}

.future-date {
  color: $gray-400;
}

.past-date {
  color: $gray-400;
  opacity: 0.5;
}

.hour-item {
  border: 1px solid $primary;
  color: $gray-400;
  max-width: 7rem;
  border-radius: 10rem;
  transition: color 0.3s, background-color 0.3s;
  padding: 1em;
  font-size: 0.8rem;
  min-width: 23%;
  margin: 0 1% 1rem;
  &:hover{
    background-color: rgba($primary, .25);
  }
  @media (min-width: 768px){
    font-size: 1rem;
    min-width: unset;
    margin: 0 1rem 1rem 0;
  }
}

.datepicker-booking-service {
  position: absolute;
  transform: translate3d(0px, 27px, 0px);
  top: 2.5rem;
  will-change: transform;
  z-index: 2;
}

.selected-date {
  background-color: $primary;
  color: #fff;
}

.available-dates-list {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 0.9375rem;
  display: flex;
}

.available-dates-list>li .available-date-item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  @media (min-width: 768px){
    padding: 2rem 0;
  }
}

.available-dates-list .available-date-name-short {
  font-weight: 400;
  color: #636363;
  position: absolute;
  top: -30px;
  line-height: 1;
}

.available-dates-list>li.disabled .available-date-day {
  color: #BBBDBF;
}

.available-dates-list>li {
  max-width: calc(100% / 7 - 2px);
  position: relative;
  text-align: center;
  margin-top: 30px;
  flex: 1;
  border-style: solid;
  border-color: $primary;
  border-width: 1px 1px 1px 0;
  transition: color 0.3s, background-color 0.3s;
  &:first-child{
    border-left-width: 1px;
  }
  &:hover{
    background-color: rgba($primary, .25);
  }
  &.selected-date:hover{
    background-color: $primary;
  }
}

.available-dates-list .available-date-count {
  background-color: #fff;
  border-radius: 50px;
  min-width: 24px;
  display: inline-block;
  text-align: center;
  font-size: 0.75rem;
  line-height: 24px;
  font-weight: 500;
  position: absolute;
  top: -8px;
  right: -8px;
  z-index: 1;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
}

.mostly-available .available-date-count {
  color: #28c76f;
  box-shadow: inset 0 0 0 1px #28c76f, 0 4px 24px 0 rgba(34, 41, 47, 0.1);
}

.non-available .available-date-count {
  color: #aaa;
}

.few-available .available-date-count {
  color: #e16d6d;
  box-shadow: inset 0 0 0 1px #e16d6d, 0 4px 24px 0 rgba(34, 41, 47, 0.1);
}

.past-date .available-date-count {
  display: none;
}

@media (min-width: 530px) {
   .available-dates-list .available-date-count {
    min-width: 30px;
    line-height: 30px;
    font-size: 1rem;
  }
}

@media (min-width: 1100px) {

  .available-date-day {
    font-size: 1.2rem;
  }

  .available-date-name-short {
    font-size: 1.2rem;
  }

  .available-dates-list .available-date-count {
    min-width: 40px;
    line-height: 40px;
    font-size: 1rem;
  }
}

@media (min-width: 1425px) {
  .available-date-day {
    font-size: 1.4rem;
  }
}
</style>
