<template>
  <b-overlay
    :show="stylistStore.isPending"
    variant="secondary"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="lg"
    class="min-vh-100"
  >
    <div
      id="user-profile"
    >
      <stylist-page-header :header-data="header" />
      <!-- Booking -->
      <section
        id="booking-service"
        class="pb-2 py-md-4 px-sm-2 px-lg-4"
      >
        <b-overlay
          :show="availableDatesStore.isPending && !stylistStore.isPending"
          bg-color="#f8f8f8"
          spinner-variant="primary"
          blur="0"
          opacity=".75"
          rounded="lg"
        >
          <b-container>
            <b-row>
              <b-col
                lg="3"
                order-lg="2"
                class="second-column"
              >
                <booking-service-details />
              </b-col>
              <b-col
                lg="9"
                class="booking-fields-box"
              >
                <booking-service-available-date />
              </b-col>
            </b-row>
          </b-container>
        </b-overlay>
      </section>
      <!--/ Booking  -->
    </div>
  </b-overlay>
</template>

<script>
import {
  BRow, BCol, BOverlay, BContainer,
} from 'bootstrap-vue'
import StylistPageHeader from '@/components/stylist/stylist-page/StylistPageHeader.vue'
import { mapActions, mapState } from 'vuex'

import BookingServiceAvailableDate from '@/components/booking/booking-service/BookingServiceAvailableDate.vue'
import BookingServiceDetails from '@/components/booking/booking-service/BookingServiceDetails.vue'
import { AlertTriangleIcon } from 'vue-feather-icons'
import { TYPE } from 'vue-toastification'

/* eslint-disable global-require */
export default {
  components: {
    BRow,
    BCol,
    BContainer,
    BOverlay,
    StylistPageHeader,
    BookingServiceDetails,
    BookingServiceAvailableDate,
  },
  data() {
    return {
      header: {
        avatar: require('@/assets/images/user-uploads/stylist-profile/avatars/avatar-s-2.jpg'),
        businessName: null,
        coverImg: require('@/assets/images/user-uploads/stylist-profile/backgrounds/timeline.jpg'),
      },
    }
  },
  computed: {
    ...mapState('StylistPageStoreModule', {
      stylistStore: state => state.stylist,
    }),
    ...mapState('BookingServiceStoreModule', {
      selectedDateTimeStore: state => state.selectedDateTime,
      availableDatesStore: state => state.availableDates,
      saveAppointmentStore: state => state.saveAppointment,
      blockDateTimeStore: state => state.blockDateTime,
    }),
  },
  watch: {
    stylistStore: {
      deep: true,
      handler(newStylistData) {
        if (newStylistData.response) {
          this.header.businessName = newStylistData.response.businessName
        }
        if (newStylistData.error) {
          this.$toast('Error fetching stylist data!', {
            icon: AlertTriangleIcon,
            type: TYPE.ERROR,
          })
        }
      },
    },
    availableDatesStore: {
      deep: true,
      handler(newAvailableDatesStore) {
        if (newAvailableDatesStore.error) {
          this.$toast('Error fetching available dates to book!', {
            icon: AlertTriangleIcon,
            type: TYPE.ERROR,
          })
        }
      },
    },
    blockDateTimeStore: {
      deep: true,
      handler(newAvailableDatesStore) {
        if (newAvailableDatesStore.error) {
          this.$toast('Error reservation date to book!', {
            icon: AlertTriangleIcon,
            type: TYPE.ERROR,
          })
        }
      },
    },
  },
  created() {
    this.clearSelectedDateTime()
  },
  mounted() {
    this.getHeaderData()
  },
  methods: {
    ...mapActions('BookingServiceStoreModule', [
      'clearSelectedDateTime',
    ]),
    ...mapActions('StylistPageStoreModule', [
      'fetchPublicStylist',
    ]),
    getHeaderData() {
      if (this.stylistStore.response.businessName === null) {
        this.fetchPublicStylist(this.$store.getters['app/getSubdomain'])
      }
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-profile.scss';

.border-left-1-gray {
  border-left: 1px solid gray;
}

@media (min-width: 992px){
  .second-column{
    align-self: flex-start;
    position: sticky;
    top: 1rem;
  }
}

@media (max-width: 991.98px) {
  .booking-fields-box {
    margin-top: 2rem;
  }
}
</style>
